
import React, { useState, useEffect, useCallback } from 'react';
import style from './style.module.css';
import { renderState, visibleState } from '../../utils/conditionalState';
import api from '../../api';

import Navigation from '../Navigation';
import Collection from '../Collection';

import GoalItem from '../GoalItem';
import GroupSelector from '../GroupSelector';
import GroupJoin from '../GroupJoin';
import GroupStart from '../GroupStart';
import RequestItem from '../RequestItem';
import AlertDialog from '../AlertSelect';

const Groups = props => {

    const [leaderboards, setLeaderboards] = useState([]);
    const [groupStatus, setGroupStatus] = useState('loading');
    const [globals, setGlobals] = useState([]);
    const [requests, setRequests] = useState([]);
    const [analytics, setAnalytics] = useState([])
    const [extrtaTab, setExtratab] = useState(false)

    const [showGroupJoin, setShowGroupJoin] = useState(false);
    const [showGroupStart, setShowGroupStart] = useState(false);
    const [alert, setAlert] = useState(null);
    const categories = ['My Group', 'Global', 'Requests'];
    const categoryIds = ['leaderboards', 'globals', 'requests', 'analytics'];
    const [selectedCategory, setSelectedCategory] = useState('leaderboards');

    const fetchGroups = async () => {
        const profile = await api.accounts.fetchProfile();

        const group = profile.acceptedGroups[0];
        const requestedGroup = profile.requestedGroups[0];

        if (group) {
            const leaderboards = await api.groups.fetchLeaderboards(group._id);
            setLeaderboards(leaderboards);
            setGroupStatus('member');
            props.updateProfile();
            try {
                const data = await api.groups.groupRequest({ 'group': `${group._id}` });
                setRequests(data.map(item => ({ ...item, groupId: group._id })));
            } catch (e) {
                // user does not have permission to view this
            }
        } else {
            if (requestedGroup) {
                props.updateProfile();
                setGroupStatus('requested');
            } else {
                props.updateProfile();
                setGroupStatus('welcome');
            }
        }

        if (group) {
            const globals = await api.groups.fetchGlobals();
            const globalsFiltered = globals.filter(function (obj) {
                return obj.userCount >= 4 && !obj.name.includes('Turtle Fitness Club') //|| obj.name.includes('Geeky');
            });
            setGlobals(globalsFiltered);

            // const analytics = await api.groups.fetchAnalytics({
            //     group: `${group._id}`,
            //     from: '2020-09-17T00:00:00.000-0400',
            //     to: '2020-09-20T00:00:00.000-0400'
            // });
            // setAnalytics(analytics.results)
            // setExtratab(analytics.enabled)
        } else {
            console.log('no access')
        }
    };
    const leaveGroup = async (groups, user) => {
        try {
            const leaveGroup = await api.groups.leaveGroup({ group: `${groups}`, user: `${user}` })
            console.log(leaveGroup)
        } catch (e) { console.log(e) }
    }

    useEffect(() => { fetchGroups() }, [props.fetchData]);

    const selectOption = option => {
        if (option.id === 'join') setShowGroupJoin(true);
        if (option.id === 'start') setShowGroupStart(true);
    };

    const finishModal = () => {
        setShowGroupJoin(false);
        setShowGroupStart(false);

        props.resync();
    };
    const leaderboardContent = renderState(groupStatus, {
        welcome: () => (
            <div className={style.welcome}>
                <div className={style.getStartedTip}>
                    Welcome to Active Marion Project! Here you can join or start your own groups to keep each other accountable.
                </div>
                <Collection
                    listLayout
                    items={[
                        {
                            id: 'join',
                            name: 'Join a Group',
                            description: 'Search for groups to join.'
                        },
                        {
                            id: 'start',
                            name: 'Start a Group',
                            description: 'Build a community yourself!'
                        }
                    ]}
                    itemType={'option'}
                    component={GoalItem}
                    onSelect={selectOption} />
            </div>
        ),
        member: () => (
            <div>
                <Collection
                    listLayout
                    items={leaderboards}
                    itemType={'leaderboards'}
                    component={GoalItem}
                    footerComponent={null} />
                    <Collection
                    listLayout
                    items={[
                        {
                            id: 'leave',
                            name: 'Leave Group',
                            description: 'Leave ' + (props.profileUser && !!props.profileUser.acceptedGroups.length ? `${props.profileUser.acceptedGroups[0].name}` : 'Groups')
                        }
                    ]}
                    // invite 4 ppl to get on leaderboard with progress bar
                    itemType={'option'}
                    component={GoalItem}
                    onSelect={()=> setAlert({
                        title: 'Leave current group?',
                        desc: 'ok',
                        doneTitle: 'Cancel',
                        confirmTitle: 'Leave',
                        confirmColor: 'rgb(255, 49, 42)',
                        onConfirm: () => {leaveGroup(props.profileUser.acceptedGroups[0]._id, props.profileUser._id);fetchGroups();setAlert(null)}
                    })} />
                    <AlertDialog alert={alert} onDone={() => setAlert(null)} />
            </div>
        ),
        requested: () => (
            <div className={style.getStartedTip}>
                You have requested to join a group. Hold on, and the group owners will let you in shortly!
                <div className={style.reload} onClick={fetchGroups}>
                    Refresh Group
                </div>
                
                <Collection
                    listLayout
                    items={[
                        {
                            id: 'leave',
                            name: 'Cancel request',
                            description: 'Cancel group request'
                        }
                    ]}
                    itemType={'option'}
                    component={GoalItem}
                    onSelect={()=> setAlert({
                        title: `Cancel current request`,
                        desc: 'ok',
                        doneTitle: 'Cancel',
                        confirmTitle: 'Leave',
                        confirmColor: 'rgb(255, 49, 42)',
                        onConfirm: () => {leaveGroup(props.profileUser.requestedGroups[0]._id, props.profileUser._id);fetchGroups();}
                    })} />
                    <AlertDialog alert={alert} onDone={() => setAlert(null)} />
            </div>
        ),
        loading: () => null
    });

    const tabsContent = visibleState(style, selectedCategory, {
        leaderboards: className => (
            <div className={className}>
                {leaderboardContent}
            </div>
        ),
        analytics: className => (
            <div className={className}>
                <Collection
                    listLayout
                    items={analytics}
                    itemType={'analytics'}
                    component={GoalItem}
                    footerComponent={null} />
            </div>
        ),
        globals: className => (
            <div className={className}>
                <Collection
                    listLayout
                    items={globals}
                    itemType={'globals'}
                    component={GoalItem}
                    footerComponent={null}
                    onSelect={() => { console.log('yo') }}
                    onSelectFooter={() => { }} />
            </div>
        ),
        requests: className => (
            <div className={className}>
                <Collection
                    listLayout
                    refresh={() => fetchGroups()}
                    items={requests}
                    itemType={'requests'}
                    component={RequestItem} />
                <div className={style.reload} onClick={fetchGroups}>
                    Refresh Group Requests
                </div>
            </div>
        ),
    });
    return (
        <Navigation navigationTitle={props.profileUser && !!props.profileUser.acceptedGroups.length ? `${props.profileUser.acceptedGroups[0].name}` : 'Groups'}>
            <div className={style.content}>
                <div className={style.selector}>
                    <GroupSelector
                        // categories={extrtaTab ? [...categories, 'Analytics'] : categories}
                        categories={categories}
                        selectedIndex={categoryIds.indexOf(selectedCategory)}
                        onSelect={index => setSelectedCategory(categoryIds[index])} />
                </div>
                {tabsContent}
            </div>
            <GroupJoin visible={showGroupJoin} onFinish={finishModal} />
            <GroupStart visible={showGroupStart} onFinish={finishModal} />
        </Navigation>
    );

};

export default Groups;
